.login_group_container {
    margin-top: 0px !important;
}

.login_social_btn {
    margin-top: 0px !important;
}

.login_google_btn {
    width: 294px !important;
}

.login_form_btn {
    margin-top: 0px !important;
}

.login_continue_btn {
    margin-top: 0px !important;
}

.ant-btn:hover,
.ant-btn:focus {
    border-color: #47a277 !important;
}

.border {
    border: 1px solid rgb(114, 114, 114);
    margin: 42px 0px 21px;
}

.main_carousel_container {
    width: 50%;
}

.main_login_carousel {
    width: 98%;
    background-image: url(/static/media/RectangleGlassEffectCurveImage.2a833ad8.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 262px;
    text-align: center;
    border-radius: 10px;
    overflow: hidden;
    position: absolute;
    top: 60%;
}

.mid_part_container {
    width: 70%;
    margin-left: 10px;
}

.carousel_box {
    width: 82%;
}

.description_p {
    color: hsl(42, 21%, 79%);
    margin: 20px 0px;
    text-align: left;
    font-size: 20px;
}

.learn-more-txt_new {
    color: #d5cebe;
    font-size: 23px;
}

.span_login_main {
    color: #FFF;
    font-weight: 200;
    font-size: 20px;
}

.span_login_main_first {
    color: #FFF;
    font-weight: 200;
}

.hr_line {
    color: #FFF;
    margin: 15px 42px;
}

.carousel_logo_img {
    width: 173px;
    margin-top: 10px;
}

.carousel_logo_box {
    display: flex;
    padding: 25px 144px 0px 8px
}


.Lochi-Login-xyz {
    text-align: center;
    letter-spacing: 2px;
    font-size: 13px;
    padding-top: 10px;
}

.form_continue_btn_enabled {
    background-color: #47a277;
    color: #FFF !important;
}

.continue_main_btn {
    height: 53px !important;
    background-color: #9bddbf !important;
    color: #47a277 !important;
}

.Sign {
    color: #47a277;
    text-decoration: underline;
    cursor: pointer;
}

.ant-form-item {
    margin-bottom: 5px !important;
}

.login_welcome_text {
    line-height: 19px !important;
}

.login_bar_line {
    width: 289px !important;
}

.login_bar_line_box {
    margin-top: 10px !important;
}

.login_main_flow_line {
    width: 49px !important;
}

.login_main_logo {
    margin-bottom: 50px !important;
}

.ant-input:focus,
.ant-input-focused {
    border-color: #9dcfbb !important;
    box-shadow: 0 0 0 2px rgb(82 196 26 / 20%) !important;
    border-right-width: 1px;
    outline: 0;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
    border-color: #9dcfbb !important;
    box-shadow: 0 0 0 2px rgb(82 196 26 / 20%) !important;
    border-right-width: 1px;
    outline: 0;
    background: #9bddbf !important;
}

.new_login_text {
    font-weight: 700 !important;
    color: #47A277 !important;
}

.ant-modal-content {
    border-radius: 20px !important;
}

.signInModalMainDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.btnLoginModal {
    border-radius: 5px !important;
    margin: 5px !important;
    height: 45px !important;
    width: 110px !important;
    text-transform: uppercase;
    font-size: 16px !important;
}

@media(max-width:768px) {
    .imgHide {
        display: none !important;
    }
}

@media(max-width:375px) {
    .iphone_se_res {
        display: flex !important;
        width: 100% !important;
        justify-content: space-evenly !important;
    }

    .google_btn_width {
        width: 67px !important;
    }

    .iphone_se_res button {
        text-indent: -9999px;
    }
}

@media(max-width:420px) {
    .iphone_se_res {
        display: flex !important;
        width: 100% !important;
        justify-content: space-evenly !important;
    }

    .google_btn_width {
        width: 67px !important;
    }

    .iphone_se_res button {
        text-indent: -9999px;
    }
}