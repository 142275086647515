.main {
    margin: auto;
    padding-top: 60px;
    background: #fcfcfc !important;
}

.Jan {
    margin-top: -19px;
}

.Data-b {
    margin-top: 15px;
    width: 185px;
    border-color: #6ea08c !important;
}

.Data-a {
    border-radius: 5px;
    width: 180px;
    margin-top: 10px;
    padding-left: 13px;
}

.Data_a_border {
    border: 1px solid #47a277;
    border-radius: 5px;
    width: 180px;
    margin-top: 10px;
}

.main-b {
    padding: 11px 23px 10px
}

.text-a {
    margin-top: 10px;
}

.Data-terms {
    width: 83%;
    padding: 0px 43px;
    background: #FFF;
    height: 532px;
    overflow-y: scroll;
}

.text-b {
    margin-top: 10px;
}

.text-c {
    margin-top: 10px;
}

.text-d {
    margin-top: 10px;
}

.Data {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #6ea08c;
    font-size: large;
    margin-top: 17px;
    width: 77%;
    margin-left: 65px;
    background: #FFF;
}

.new_Heading_class {
    font-weight: 600;
    font-size: 26px;
}

.terms_condition_main {
    margin: 0 !important;
}

.Data-a_border {
    display: inherit;
    color: #47a277 !important;
    border-radius: 5px;
    width: 100%;
    margin-top: 10px;
    padding-left: 13px;
    font-weight: 700;
    cursor: pointer;
}

.Data-a_border p {
    margin-left: 10px;
}

.side_point_color {
    display: inherit;
    color: #47a277 !important;
    border: 2px solid #6ea08c !important;
    width: 100%;
    margin-top: 10px;
    padding-left: 13px;
    font-weight: 700;
    border-radius: 10px;
}

.main_hr_line {
    height: 1px;
    background-color: black;
}

.side_point_color p {
    margin-left: 10px;
}

.heading_main_term {
    display: flex;
    margin-top: 10px;
}

.reset-password-backarrow-icon {
    width: 35px;
    height: 35px;
    cursor: pointer;
}

.heading_main_term p {
    margin-left: 10px;
}

.side_point_color:hover {
    color: #47a277 !important;
}

*::-webkit-scrollbar {
    width: 7px;
}

*::-webkit-scrollbar-thumb {
    background-color: #6ea08c;
    border-radius: 10px;
    border: 1px solid #6ea08c;
}

.main_logo_box {
    display: none;
    align-items: center;
    justify-content: center;
}

.main_term_logo_bottom {
    margin: 53px 128px 25px 79px;
}


@media (max-width:450px) {
    .main_logo_box {
        display: flex !important;
        justify-content: center;
        align-items: center;
    }
}

@media (max-width:375px) {
    .Data {
        display: none !important;
    }

    .main_term_logo_bottom {
        margin: 53px 38px 25px 18px;
    }

    .Data-terms {
        width: 99%;
        padding: 0px 10px;
        background: #FFF;
        height: 532px;
        overflow-y: scroll;
    }

    .new_Heading_class {
        font-weight: 600;
        font-size: 17px;
    }

    .terms_signup_log {
        width: 90% !important;
        height: 88% !important;
    }
}

@media (max-width:412px) {
    .Data {
        display: none !important;
    }

    .new_Heading_class {
        font-weight: 600;
        font-size: 17px;
    }

    .main_term_logo_bottom {
        margin: 53px 38px 25px 18px;
    }

    .Data-terms {
        width: 99% !important;
        padding: 0px 10px !important;
        background: #FFF;
        height: 532px;
        overflow-y: scroll;
    }

    .terms_signup_log {
        width: 90% !important;
        height: 88% !important;
    }
}
