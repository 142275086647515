.main_footer{
height: 0% !important;
}
.footer {
    height: 300px;
    display: flex;
    color: white;
    width: auto;
    justify-content: space-around;
    align-items: center;
    background-image: url("../../blocks/email-account-registration/assets/footerCurveImage.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
  .footer-items {
    margin-top: 80px;
    width: 70%;
  }
  .footer-img {
    height: 150px;
    width: 180px;
  }
  .footer-logo span {
    font-size: 50px;
    padding-top: 40px;
  }
  .footer-logo p {
    font-size: 10px;
  }
  .footer-link-items {
    display: flex;
    flex-direction: column;
    margin: 20px auto;
    text-align: center;
    width: 160px;
    box-sizing: border-box;
  }
  
  .footer .ant-col-lg-6 {
    max-width: 22% !important;
  }
  .footer-link-items h6 {
    text-align: left;
  }
  .footer-list.ant-row {
    display: flex;
    justify-content: center;
  }
  .footer-link-items a {
    list-style: none;
    color: white !important;
    text-decoration: none;
    text-align: left;
  }
  .footer-collapse {
    margin: 30px 100px;
    display: none;
  }
  .footer
    .ant-space.css-dev-only-do-not-override-1me4733.ant-space-horizontal.ant-space-align-center {
    display: flex;
    justify-content: space-between;
    height: 50px;
    border-bottom: 1px solid white;
  }
  .footer-collapse {
    margin-top: 50px;
  }
  .footer .ant-collapse {
    color: white;
    font-size: 16px;
    background-color: white;
    border: 1px solid #d9d9d9;
  }
  .footer .ant-collapse > .ant-collapse-item:last-child {
    border: none;
  }
  .footer .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    color: white;
    padding: 12px 2px;
  }
  /* .ant-collapse.ant-collapse-icon-position-start.css-dev-only-do-not-override-1me4733 {
    border: none;
    background-color: transparent;
    border-radius: none;
  } */
  .footer
    .ant-collapse.ant-collapse-icon-position-end.css-dev-only-do-not-override-1me4733 {
    border: none;
    background-color: transparent;
    border-radius: none;
  }
  .footer .ant-collapse > .ant-collapse-item {
    border-bottom: 1px solid white;
    border-radius: 0px;
  }
  
  .footer-text {
    padding: 10px;
    color: rgb(54, 119, 81);
    padding: 10px;
    color: rgb(54, 119, 81);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .footer .ant-collapse > .ant-collapse-item > .ant-collapse-header{
    color: #FFF !important;
    background-color: #4fa97f !important;
  }
  .footer_heading {
    color: #FFF !important;
    font-size: 15px !important;
  }
  .footer-logo{
  padding-top: 10px; 
  }
  @media screen and (max-width: 991px) {
    .footer-items {
      margin: 10px 20px;
    }
    .footer-img {
      height: 100px;
      width: 130px;
    }
  }
  @media (max-width: 768px) {
    .footer {
      flex-direction: column-reverse;
      text-align: left;
      background-image: none;
      background: linear-gradient(
        to bottom right,
        rgb(65, 166, 127),
        rgb(108, 199, 167)
      );
      height: auto;
    }
    .footer-img {
      height: 150px;
      width: 170px;
    }
    .footer .footer-items {
      display: none;
    }
    .footer-collapse {
      display: block;
      width: 70%;
    }
  }
  @media (max-width: 480px) {
    .footer-collapse {
      margin: 30px auto;
    }
  }
  