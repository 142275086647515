/* @import url('../src/font/stylesheet.css'); */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('../../blocks/splashscreen/src/splashScreen.web.css');
@import url('../../blocks//email-account-registration/src/SignUp.web.css');
@import url('../../blocks/email-account-login/src/loginAccount.web.css');
@import url('../../blocks/TermsAndConditions3/src/TermsAndConditionPage.web.css');
@import url('../../components/src/Footer.web.css');
@import url('../../components/src/NavbarMain.web.css');
@import url('../../blocks/shoppingcart//src/ShoppingCartPage.web.css');





/* :root {
  --color-primary: #3f7a4f;
} */


* {
  font-family: 'Poppins', sans-serif;
}

body {
  padding-right: 0 !important;
  /* user-select: none; */
  background-coloR: #f7f7f8 !important;
}

/* iframe {
  pointer-events: none !important;          
} */

/* li {
  list-style: none;
} */

.Main-container {
  padding: 0 65px;
}

@media(max-width:767px) {
  .Main-container {
    padding: 0 30px;
  }

}

@media(max-width:575px) {
  .Main-container {
    padding: 0 15px;
  }

}

@media(max-width:375px) {
  .Main-container {
    padding: 0 10px;
  }
}

#zmmtg-root {
  display: none;
}

body {
  overflow: auto !important;
}

body>#zmmtg-root {
  display: none !important;
}

#zmmtg-root,
.meeting-client,
.meeting-client-inner {
  width: 100% !important;
  height: 100% !important;
  position: relative !important;
}

#wc-footer {
  bottom: auto !important;
  width: 100% !important;
}

#dialog-join {
  width: 100% !important;
}

#sv-active-video,
.active-main,
#sv-active-speaker-view,
.main-layout {
  height: 100% !important;
  width: 100% !important;
}

.suspension-window {
  transform: translate(-444px, 10px) !important;
}

.ant-notification-notice-close {
  color: black !important;
}

.p {
  margin-top: 0 !important;
  margin-bottom: 1em !important;
}