.navbar-section {
  width: 100%;
  overflow-y: unset;
}

.navbar-logo {
  padding: 10px;
  font-size: 35px;
}

span.ant-badge.ant-badge {
  color: white;
  font-size: 24px;
  padding-left: 0px;
}

.navbar-logo:hover {
  cursor: pointer
}

.navbar-main-div {
  height: auto !important;
  width: 90%;
  margin: auto;
}

.navbar-logo-text {
  color: #389c6a;
}

.navbar-section button.navbar-toggler.collapsed {
  border: none;
  outline: none;
}

.navbar-section .navbar>.container,
.navbar-section .navbar>.container-fluid,
.navbar-section .navbar>.container-lg,
.navbar-section .navbar>.container-md,
.navbar-section .navbar>.container-sm,
.navbar-section .navbar>.container-xl,
.navbar-section .navbar>.container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.navbar-search {
  width: 60%;
  height: auto;
  padding: 5px;
  border: 1px solid grey;
  border-radius: 10px;
}

.navbar-ul {
  width: 100%;
  margin: 0 auto;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-section.me-auto.my-2.my-lg-0.navbar-nav.navbar-nav-scroll {
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.d-flex {
  border: 1px solid grey;
  width: 100%;
  margin: 10px auto;
  padding: 5px;
  border-radius: 10px;
}

.navbar-main-div .navbar-toggler {
  border: none;
}

input.me-5.form-control {
  margin-right: 1rem !important;
  border: none;
  outline: none !important;
  box-shadow: none;
  background-color: transparent;
}

.search-text {
  height: 40px;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-img {
  height: 18px;
  width: 18px;
}

.navbar-section .search-text span {
  padding-left: 15px;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
}
.navbar-section .search-text span:hover {
  color: #A5D0BA !important;
}

.search-text.ant-btn-primary {
  background-color: rgb(60, 163, 123);
  height: 41px !important;
  border-radius: 8px;
  outline: none;
  border: none;

  display: flex;
  align-items: center;
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
  background-color: #389c6a !important;
  opacity: .7;
  color: #fefefe !important;
}

.search-text.ant-btn-primary:not(:disabled):hover {
  color: #fff;
  background-color: rgb(60, 163, 123) !important;
}

.vertical-align-1 {
  margin-top: 5px;
  border-left: 1px solid grey;
  height: 28px;
  padding-right: 3px;
}

.navigation-icon-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

/* .navbar-collapse .navbar-nav-scroll {
    overflow-y: hidden !important;
    overflow-x: hidden !important;
  } */
.navigation-icon-item {
  padding-right: 10px;
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  font-size: 25px;
}

.navigation-icon-item span {
  padding-left: 20px;
}

.ant-scroll-number {
  background-color: #389c6a !important;
}

.badge_Header span {
  padding: 0 !important;
}

.vertical-align-2 {
  border-left: 1px solid black;
  margin-top: 10px;
  height: 40px;
  margin-left: 5px;
  margin-right: 5px;
}

.user-login {
  font-size: 25px;
  padding: 10px;
  display: flex;
}

.navbar-section.navbar-toggler {
  border: none;
}

.navbar-section .navbar-toggler:focus {
  box-shadow: none;
  border: none;
  padding: 0px !important;
}

.ant-select {
  display: flex !important;
  align-items: center !important;
}

img.navigation-icon {
  height: 22px;
  width: 22px;
}

/* User login dropdown css */

span.ant-dropdown-menu-title-content a {
  text-decoration: none !important;
}

.Parent-dropDown {
  position: relative;
}

.Child-dropDown {
  position: absolute;
  top: 25px;
  right: -80px;
  z-index: 1;
  background-color: white;
  border: 1px solid black;
  height: auto;
  width: 200px;
  border-radius: 20px;
}

.Child-dropDown ul {
  margin-top: 10px;
  list-style: none;
  font-size: 15px;
}

.Child-dropDown li {
  margin-bottom: 5px;
}

.dropDown-list-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 0;
}

.menu-icons {
  margin-left: 0px;
  font-size: 20px;
  color: black !important;
}

.menu-name {
  margin-left: 20px;
  font-size: 15px;
  font-weight: 400;
  color: black !important;
}

.horizontel {
  width: 100%;
  margin-left: -10px;
  color: black;
}

.zipcode-section {
  display: flex;
  padding: 5px;
  flex-direction: column;
}

.title-zipCode {
  font-weight: 600;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: unset !important;
}

.nav_main_react {
  max-height: 100px !important;
  width: 100% !important;
}

.ant-btn {
  height: 49px !important;
}

@media screen and (max-width: 991px) {
  .navbar-section {
    width: 90%;
    margin: 20px auto;
  }

  .navbar-section .search-text span {
    text-decoration: none;
    display: none;
  }

  .navbar-section .navbar-search {
    width: 100%;
  }

  .navigation-icon-item {
    padding: 10px;
    display: flex;
    width: 60%;
    justify-content: space-evenly;
    align-items: center;
    font-size: 25px;
  }

  .icon {
    width: 50%;
  }

  .vertical-align-2 {
    margin-top: 15px;
  }

  .navbar-nav-scroll {
    max-height: var(--bs-scroll-height, 2vh);
    overflow-y: none !important;
  }
}

@media (max-width: 768px) {
  .container-fluid {
    flex-direction: row-reverse;
  }

  .navbar>.container,
  .navbar>.container-fluid,
  .navbar>.container-lg,
  .navbar>.container-md,
  .navbar>.container-sm,
  .navbar>.container-xl,
  .navbar>.container-xxl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: flex-end;
  }

  .navbar-logo {
    display: none;
  }

  .navbar-logo-text {
    display: block;
    padding-left: 150px;
    color: #389c6a;
    ;
  }

  .logo-img {
    height: 50px;
    width: 50px;
    color: green;
  }
}

@media (max-width: 575px) {
  .navbar-logo-text {
    display: block;
    padding-left: 70px;
  }

  .navigation-icon-item {
    padding: 10px;
    display: flex;
    width: 40%;
    justify-content: space-evenly;
    align-items: center;
    font-size: 25px;
    margin-right: 20px;
  }

  .navbar-toggler.collapsed {
    padding: 0px !important;
  }

  .navbar-section.me-auto.my-2.my-lg-0.navbar-nav.navbar-nav-scroll {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 414px) {
  .navbar-logo-text {
    display: block;
    padding-left: 50px;
  }

  input.me-5.form-control {
    width: 175px !important;
  }

  .navigation-icon-item span {
    padding-left: 15px;
  }
}

@media (min-width: 1920px) {
  input.me-5.form-control {
    width: 100%;
  }
}


@media (max-width: 415px) {

  .target-nav {
    width: 115% !important;
    margin-left: -10px;
  }

  .d-flex {
    width: 90%;
    margin-left: -10px;
  }
}

@media (max-width:390px) {

  .d-flex {
    width: 90%;
    margin-left: -10px;
  }

  .navigation-icon-wrapper {
    margin-left: -25px;
  }

  input.me-5.form-control {
    width: 135px !important;
  }
}

.txtToggle {
  margin: 0px 5px;
  font-size: 15px;
  font-weight: 400;
}

.ant-switch-checked {
  background-color: #A5D0BA !important;
}

.ant-switch {
  background-color: #A5D0BA !important;
}

.ant-switch-handle::before {
  background-color: #389c6a !important;
}

.ant-select-dropdown {
  border-radius: 10px !important;
  border: 1px solid #161616;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: #389c6a !important;
  font-weight: 600;
  background-color: #fff !important;
}

.navbar-section .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  color: #389c6a !important;
}

.navbar-section .ant-select-arrow {
  color: #389c6a !important
}

.logo-img {
  height: 50px;
  width: 60px;
  padding-right: 10px;
}