.splash_main_img{
    background-image: url("../assets/splashScreenImage.png"); 
    background-position: center;
    background-size: cover;
    height: 100vh;
    font-size:50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.inner_splash_logo{
  width: 225px;
  height: 225px;
  background-color: white;
  border-radius: 16px;
}

