@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
.splash_main_img{
    background-image: url(/static/media/splashScreenImage.76daee69.png); 
    background-position: center;
    background-size: cover;
    height: 100vh;
    font-size:50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.inner_splash_logo{
  width: 225px;
  height: 225px;
  background-color: white;
  border-radius: 16px;
}


@font-face {
  font-family: "NexaBlack";
  src: url(/static/media/NexaBlack.9e610869.otf) format("truetype");
}

@font-face {
  font-family: "NexaBold";
  src: url(/static/media/NexaBold.35418d8f.otf) format("truetype");
}

@font-face {
  font-family: "Nexabook";
  src: url(/static/media/NexaBook.459f4dc6.otf) format("truetype");
}

@font-face {
  font-family: "NexaBlackItalic";
  src: url(/static/media/NexaBlackItalic.2e7dbd63.otf) format("truetype");
}

@font-face {
  font-family: "Nexaheavy";
  src: url(/static/media/NexaHeavy.b868d275.otf) format("truetype");
}

@font-face {
  font-family: "NexaLightItalic";
  src: url(/static/media/NexaLightItalic.10b324cc.otf) format("truetype");
}

@font-face {
  font-family: "NexaRegular";
  src: url(/static/media/NexaRegular.43af9a82.otf) format("truetype");
}

@font-face {
  font-family: "NexaThin";
  src: url(/static/media/NexaThin.88d262ee.otf) format("truetype");
}

@font-face {
  font-family: "NexaThinItalic";
  src: url(/static/media/NexaThinItalic.28b68f7a.otf) format("truetype");
}

@font-face {
  font-family: "NexaXBold";
  src: url(/static/media/NexaXBold.51e166c5.otf) format("truetype");
}

* {
  font-family: "Poppins", sans-serif;
}

.signLeftPartBox {
  width: 100%;
  position: relative;
}

.ant-row.css-dev-only-do-not-override-mxhywb {
  width: 100%;
  height: 903px;
}

.main-logo-div:hover {
  cursor: pointer !important;
}

.main-logo-div-container:hover {
  cursor: pointer;
}


.signRightPartBox {
  width: 80%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.password_match_icon {
  margin-bottom: 6px !important;
  height: 18px !important;
}

.rightPartContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.logobox {
  width: 213px;
  height: 168px;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #FFF !important;
}

.signUpTextBox {
  width: 100%;
  text-align: center;
}

.signUpText {
  font-size: 28px;
  color: #47a277;
  font-weight: 700;
}

.role-chart-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 50px;
}

.role-chart {
  border-radius: 12px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #9dcfbb;
}

.role-chart-active {
  border-radius: 12px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #47a277;
}

.ant-select.ant-select-in-form-item {
  background-color: #FFF !important;
}

.roleText {
  color: #9dcfbb;
  font-size: 18px;
  font-weight: 500;
  margin-top: 8px;
}

.roleText-active {
  color: #47a277;
  font-size: 18px;
  font-weight: 500;
  margin-top: 8px;
}

.flow-chart {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flow-line {
  width: 50px;
  background: #9dcfbb;
  height: 1px;
  margin: 0 10px 0 10px;
}

.flow_line_select {
  width: 50px;
  background: #47a277;
  height: 1px;
  margin: 0 10px 0 10px;
}

.flow-label-line {
  width: 50px;
  height: 1px;
  margin: 0 10px 0 10px;
}

.flow-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;
}

.userContainer {
  display: flex;
  justify-content: center;
  height: 105px;
}

.shoperBox {
  width: 75px;
  margin-right: 24px;
  height: 70px;
  margin-left: 25px;
  border-radius: 10px;
  box-shadow: 0px 1px 3px 0px #6c75
}

.shoper-img {
  margin: 0;
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.shoper-img-text {
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: #47a277;
  margin-top: 24px;
}

.continue-btn {
  width: 291px !important;
  height: 50px !important;
  border-radius: 10px !important;
  border: 0;
  background-color: #47a277;
  color: white;
  font-size: 17px;
  cursor: pointer;
  letter-spacing: 1px;
}

.btn-group-container {
  width: 100%;
}

.signUpWith {
  font-size: 14px;
  font-weight: 300;
  text-align: center;
  width: 170px;
  margin-top: 10px;
}

.continue-btn-box {
  text-align: center;
  margin-top: 5px;
}

.google-btn {
  width: 268px;
  height: 50px;
  border-radius: 10px;
  color: black;
  font-size: 16px;
  border: 1px solid #9dcfbb;
  cursor: pointer;
  position: relative;
}

.social_string {
  line-height: 1px !important;
}

.google_btn_main {
  width: 291px;
}

.google_btn_width {
  width: 291px !important;
}

.fb-btn-grup {
  margin-top: 15px;
}

.bar-line {
  width: 284px;
  height: 1px;
  background-color: black;
}

.about-title-container .custom-collapse p {
  font-size: 16px !important;
}

.bar-line-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
}

.already-text {
  margin: 0;
  font-size: 18px;
  font-weight: 400;
  color: #868686;
}

.login-text {
  color: #47a277;
  font-weight: 500;
  padding-bottom: 0px;
  cursor: pointer;
  text-decoration: underline;
}

.sinup_log {
  width: 100%;
  height: 100vh;
}

.main_flow_line {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.main_inner_flow {
  width: 49px !important;
  background-color: #656565 !important;
}

.shopper_icon {
  width: 42px;
  cursor: pointer;
  margin-top: 23px;
}

.shoperBox-transform {
  width: 75px;
  margin-right: 24px;
  height: 70px;
  margin-left: 25px;
  border-radius: 10px;
  box-shadow: 0px 1px 3px 0px #6c75;
  transform: scale(1.2);
  background-color: #4fa97f;
}

.google_icon {
  width: 20px;
  height: 21px;
  position: absolute;
  left: 25px;
  top: 14px;
}

.facebook_icon {
  width: 14px;
}


.sign-indv-form {
  width: 300px;
}

.ant-form-item.css-dev-only-do-not-override-mxhywb {
  margin-bottom: 0;
}

.form-accnt-box {
  height: 40px !important;
  border-radius: 15px !important;
}

.form-accnt-box-border {
  height: 40px !important;
  border-radius: 15px !important;
  border-color: #47a277 !important;
}

:where(.css-dev-only-do-not-override-mxhywb).ant-input:hover {
  border-color: #d9d9d9 !important;
  border-inline-end-width: 1px;
}

:where(.css-dev-only-do-not-override-mxhywb).ant-input:focus,
:where(.css-dev-only-do-not-override-mxhywb).ant-input-focused {
  border-color: #47a277 !important;
  box-shadow: none !important;
  border-inline-end-width: 1px;
  outline: 0;
}

.form-text {
  margin-bottom: 0px;
  padding-left: 15px;
}

:where(.css-dev-only-do-not-override-mxhywb).ant-input-affix-wrapper:hover {
  border-color: #d9d9d9 !important;
  border-inline-end-width: 1px;
}

:where(.css-dev-only-do-not-override-mxhywb).ant-input-affix-wrapper:focus,
:where(.css-dev-only-do-not-override-mxhywb).ant-input-affix-wrapper-focused {
  border-color: #47a277 !important;
  box-shadow: none !important;
  border-inline-end-width: 1px;
  outline: 0;
}

:where(.css-dev-only-do-not-override-mxhywb).ant-btn-default:not(:disabled):hover {
  color: white !important;
  border-color: #47a277 !important;
}

.form-button {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.form_Done {
  margin-top: 10px !important;
}

.star-color {
  color: #47a277;
  font-size: 16px;
}

.new_input {
  display: none;
}

.ant-btn {
  font-size: 20px !important;
  height: 50px !important;
}

.category_margin {
  margin-top: 10px !important;
}

.ant-input:focus,
.ant-input-focused {
  border-color: #4fa97f;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  border-right-width: 1px;
  outline: 0;
}

.form_continue_btn {
  height: 50px !important;
  width: 299px !important;
  border-color: #9dcfbb !important;
  background: #9dcfbb !important;
  color: #FFF !important;
  border-radius: 10px !important;
  cursor: not-allowed;
}

.form_continue_btn_user {
  background: #9dcfbb !important;
}

.vendor_form_color {
  color: #4fa97f !important;
}

.form_continue_btn_enabled {
  height: 50px !important;
  border-radius: 10px !important;
  width: 291px !important;
  background: #4fa97f !important;
  color: #FFF !important;
}

.form_continue_btn_enabled_user {
  background: #4fa97f !important;
  transition: box-shadow 250ms;
}

.form_continue_btn_enabled_user:hover{
box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
}

.ant-input:hover {
  border-color: #47a277 !important;
  border-right-width: 1px;
}

.ant-input:focus {
  box-shadow: 0 0 0 2px rgb(82 196 26 / 20%);
  background-color: #9bddbf !important;
}

.ant-input-affix-wrapper:hover {
  border-color: #47a277 !important;
  border-right-width: 1px;
}

.signup_datepicker {
  width: 100%;
  height: 40px !important;
  border-radius: 15px !important;
  border: 1px solid #d9d9d9;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  box-shadow: 0 0 0 2px rgb(82 196 26 / 20%) !important;
  background-color: #9bddbf !important;
}

.form_continue_btn_enabled:hover {
  color: #FFF !important;
}

.contact_select_input {
  height: 40px !important;
  border-radius: 15px !important;
  border: 1px solid #d9d9d9 !important;
}

.check_terms_condition {
  display: flex;
}

.terms_checkbox {
  width: 36px;
}

.terms_checkbox .ant-checkbox-wrapper {
  border-radius: 8px;
}

.terms_checkbox .ant-checkbox-input {
  border-radius: 8px;
}

.terms_checkbox .ant-checkbox-inner {
  border-radius: 8px;
  font-size: 16px;
  transform: scale(1.2);
}

.terms_condition_span {
  color: #4fa97f !important;
  border-bottom: 1px solid #47a277;
  cursor: pointer;
}

.bar_line_box_terms {
  display: flex;
}

.terms_text {
  font-size: 14px !important;
  margin-top: 5px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #4fa97f !important;
  border-color: #4fa97f !important;
  border-radius: 4px;
}

.ant-checkbox-inner::after {
  width: 8.714px !important;
  height: 13.143px !important;
}

.ant-picker:hover,
.ant-picker-focused {
  border-color: #4fa97f !important;
  border-right-width: 1px;
}

.main_title {
  position: absolute;
  top: 4%;
  left: 9%;
  font-size: 63px;
  color: #FFF;
  font-weight: 400;
  letter-spacing: 2px;
}

.new_class_vendor {
  height: 50px !important;
  border-radius: 10px !important;
  width: 291px !important;
  border-color: #9bddbf !important;
  background: #9bddbf !important;
  cursor: not-allowed;
}

.ant-checkbox-inner :hover {
  color: #4fa97f !important;
}

.ant-checkbox-inner {
  position: relative;
  top: 0;
  left: 0px;
  display: block;
  width: 25px !important;
  height: 25px !important;
  font-size: 39px;
}

.pdf_file_name {
  margin-top: 10px;
  width: 40%;
  border-bottom: 1px solid #4fa97f;
  color: #4fa97f;
  overflow: hidden;
  text-overflow: ellipsis;
}

.new_enabled {
  background-color: #4fa97f !important;
  color: #FFF !important;
}

.form_new_class {
  background-color: #9bddbf !important;
  color: #47a277 !important;
}

.cancel_icon {
  color: red !important;
}

.cancel_pdf {
  display: flex;
  align-items: center;
}

.terms_margin {
  margin-top: 10px !important;
}

.new_upload_icon {
  font-size: 43px;
  padding-right: 18px;
  margin-left: 45px;
}

.upload_evidence_data {
  text-align: center;
  padding: 0px 29px
}

.new_class_upload {
  color: #fff !important;
  margin-bottom: 10px;
}

.learn-more-parent {
  background-color: var(--background-color);
  height: -moz-fit-content !important;
  height: fit-content !important;
}

.resp-learn-more-top {
  display: none;
  align-items: center;
  justify-content: space-around;
  width: 90%;
}

.resp-learn-more-top:hover {
  cursor: pointer !important;
}

.shopper_icon_learnmore {
  width: 55px;
  cursor: pointer;
}

.learn-more-top-nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 80%;
  margin: auto;
  margin-bottom: 20px !important;
}

.learn-more-txt-mobile-text {
  display: none;
  font-size: 18px;
  text-align: center;
  color: black;
}

.toggle-tab-container {
  display: flex;
}

.upper-tab-text {
  color: #47a277;
  text-decoration: underline;
}

.learn-more-parent * {
  font-family: "Poppins", sans-serif;
}

.l-about-us-banner {
  position: relative;
  display: flex;
  justify-content: center;
}

.l-about-us-title-txt {
  font-weight: bold;
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.about-title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  width: 1250px;
}

.custom-collapse {
  border-bottom: 1px solid #e8e8e8;
}

.custom-collapse .ant-collapse-borderless {
  width: 1250px;
  background-color: transparent !important;
}

.custom-panel-header {
  font-weight: bold;
  font-size: 20px;
}

.join-community-banner {
  display: flex;
  flex-direction: column;
  margin: auto;
  justify-content: center;
  align-items: center;
  margin-top: 40px !important;
  background: linear-gradient(to right, #fff, #a1e4c5);
  width: 1250px;
  border-radius: 8px;
  margin-bottom: 40px !important;
}

.shopper-community-banner {
  display: flex;
  justify-content: center;
  margin: auto;
  align-items: center;
  margin-top: 40px !important;
  background: linear-gradient(to right, #a1e4c5, #fff);
  width: 1250px;
  border-radius: 8px;
  margin-bottom: 40px !important;
  height: 300px;
}

.join-community-text {
  font-weight: bold;
  font-size: 20px;
  max-width: 78%;
  text-align: center;
  padding-top: 25px;
}

.sign-up-notify {
  max-width: 60% !important;
  padding: 10px !important;
}

.shopper-community-desc-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-weight: bold;
}

.shopper-community-text {
  font-size: 20px;
  max-width: 70%;
}

.few-clicks {
  max-width: 65% !important;
}

.custom-icon-about-us img {
  width: 20px;
  height: 20px;
  margin-inline: 10px;
}

.custom-icon-about-us {
  font-size: 16px;
}

.tired-banner-container {
  position: absolute;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  bottom: 0px;
}

.tired-p-text {
  font-size: 20px;
  max-width: 80%;
  opacity: 0.8;
}

#custom-button {
  background: linear-gradient(to right, #a1e4c5, #47a277) !important;
  transition: 0.3s ease-in;
  padding: 10px 20px;
  color: white;
  margin-bottom: 20px;
  border-radius: 10px !important;
  border: none !important;
  height: 40px !important;
  width: 175px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.learn-more-txt {
  position: absolute;
  bottom: -1px;
  color: white;
  font-size: 20px;
  left: 17%;
}


#custom-button :hover,
#custom-button :focus {
  background-color: #4fa97f !important;
  transition: 0.3s ease-in;
  padding: 10px 20px;
  border: none !important;
  color: white !important;
  margin-bottom: 20px;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-img-abt {
  height: 200px;
  width: 200px;
}

.logo-container-abt {
  position: absolute;
  right: 18%;
  top: 25px;
}


.wlcm-lochi-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  text-align: center;
  color: white;
  max-width: 45%;
  top: 50%;
  left: 33%;
}

.wlcm-lochi-h2 {
  color: white;
}

.mission-text {
  font-size: 18px;
  opacity: 0.8;
}

.founder-div {
  position: absolute;
  color: white;
  bottom: 65px;
  font-size: 18px;
  text-align: right;
  right: 20%;
  opacity: 0.8;
}

.small-business-txt {
  margin-left: -30px;
}

.what-is-lochi-cont {
  display: flex;
  justify-content: space-between;
  margin: 40px auto;
  width: 1250px;
  align-items: center;
}

.beginText {
  color: #8d8d8d;
}

.what-is-lochi-txt {
  margin-inline: 38px;
  font-size: 20px;
  text-align: center;
  font-weight: bold;
}

.welcom-lochi-mart-cont {
  position: absolute;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  bottom: 0px;
  width: 1250px;
  margin: auto;
}

.business-description-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-main-lochi {
  height: 200px;
}

.welcom-lochi-mart-cont h1,
.welcom-lochi-mart-cont h2 {
  color: white;
  text-align: center;
}

.wlcm-lochi-que-cont {
  display: flex;
  margin: auto;
}

.lochi-benefits-sub-points {
  display: flex;
  align-items: center;
  width: 90%;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}

.lochi-benefits-sub-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
}

.lochi-benefits-main-container {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  width: 90%;
}

.wlcm-lochi-que-cont p {
  margin-inline: 25px;
  font-size: 18px;
  text-align: center;
  opacity: 0.8;
}

.upper-box-transform {
  width: 55px;
  margin-right: 24px;
  height: 55px;
  margin-left: 25px;
  border-radius: 10px;
  box-shadow: 0px 1px 3px 0px #6c75;
  transform: scale(1.2);
  background-color: #4fa97f;
}

.ant-collapse-header {
  color: black !important;
  font-weight: 600 !important;
}

.lochi-learn-more-link {
  text-decoration: underline !important;
  color: #47a277 !important;
}


@media (max-width: 1250px) {

  .learn-more-txt-mobile-text {
    display: block;
  }

  .learn-more-txt {
    display: none;
  }

  .what-is-lochi-cont {
    flex-direction: column;
    width: 90%;
  }

  .about-title-container,
  .join-community-banner,
  .custom-collapse .ant-collapse-borderless,
  .welcom-lochi-mart-cont {
    width: 90%;
  }

  .about-us-banner-imgs {
    width: 90%;
  }

  .logo-img-abt {
    height: 150px;
    width: 150px;
  }

  .logo-container-abt {
    position: absolute;
    right: 70px;
    top: 10px;
  }

  .wlcm-lochi-container {
    top: 30%;
  }

  .shopper-community-banner {
    width: 90%;
    flex-direction: column;
    height: -moz-fit-content;
    height: fit-content;
  }

  .shopper-community-text {
    font-size: 18px;
  }

  .shopper-community-desc-cont {
    margin-top: 20px;
  }

  .tired-p-text {
    font-size: 18px;
  }

  .logo-main-lochi {
    height: 150px !important;
  }

  .img-small-businesses-banner {
    width: 90%;
  }

  .wlcm-lochi-que-cont,
  .need-to-join-lochi-container {
    display: none;
  }

  .lochi-benefits-main-container {
    display: flex;
  }

  .platform-main-text {
    font-size: 22px !important;
    text-align: center;
  }

  .business-description-container {
    margin: 10px;
    width: 90%;
  }

}

@media (max-width: 1050px) {
  .founder-div {
    right: 90px;
    bottom: 10px;
  }

}

@media (max-width: 900px) {

  .about-title-container,
  .custom-collapse .ant-collapse-borderless {
    align-items: flex-start;
  }

  .resp-learn-more-top {
    display: flex;
  }

  .main-logo-div {
    display: none;
  }



  .platform-main-text {
    font-size: 18px !important;
    text-align: center;
  }

  .tired-p-text {
    font-size: 16px;
  }

  .logo-main-lochi {
    height: 150px !important;
  }

  .img-small-businesses-banner {
    width: 90%;
    height: 250px;
  }

  .l-about-us-title-txt {
    font-size: 25px
  }

  .join-community-text {
    font-size: 18px;
  }

  .about-us-banner-imgs {
    height: 225px;
  }

  .logo-container-abt {
    position: absolute;
    right: 35px;
    top: 10px;
  }

  .logo-img-abt {
    height: 90px;
    width: 90px;
  }

  .wlcm-lochi-container {
    top: 40px;
  }

  .wlcm-lochi-h2 {
    color: white;
    font-size: 20px;
  }

  .mission-text {
    font-size: 16px;
  }

  .founder-div {
    font-size: 16px;
    bottom: 10px;
    right: 60px;
  }

  .custom-collapse {
    width: inherit;
  }

  .business-description-container {
    margin: 10px;
    width: 90%;
  }

  .lochi-benefits-sub-points p {
    padding: 10px;
    font-size: 16px;
  }

  .business-images {
    width: 150px;
    height: 250px;
  }

  .what-is-lochi-txt {
    font-size: 16px;
  }

}

@media (max-width: 580px) {
  .logo-img-abt {
    height: 60px;
    width: 60px;
  }

  .business-images {
    width: 100px;
    height: 200px;
  }

  .what-is-lochi-txt {
    font-size: 14px;
  }

  .platform-main-text {
    font-size: 16px !important;
    text-align: center;
  }

  .wlcm-lochi-container {
    top: 20px;
  }

  .wlcm-lochi-h2 {
    color: white;
    font-size: 16px;
  }

  .mission-text {
    font-size: 12px;
  }

  .lochi-benefits-sub-points p {
    padding: 10px;
    font-size: 15px;
  }

  .founder-div {
    font-size: 12px;
    bottom: 3px;
  }

}


.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
  background-color: #fff;
  border-color: #ff4d4f !important;
  border-radius: 10px;
  height: 100%;
}

sup {
  top: -4px !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
  border-color: #47a277 !important;
}

.ant-checkbox-checked::after {
  border: none !important;
}

@media(max-width:375px) {
  .iphone_se_res {
    display: flex !important;
    width: 100% !important;
    justify-content: space-evenly !important;
  }

  .google_btn_width {
    width: 67px !important;
  }

  .upper-tab-text {
    font-size: 12px;
  }

  .iphone_se_res button {
    text-indent: -9999px;
  }
}

@media(max-width:414px) {
  .iphone_se_res {
    display: flex !important;
    width: 100% !important;
    justify-content: space-evenly !important;
  }

  .google_btn_width {
    width: 67px !important;
  }

  .iphone_se_res button {
    text-indent: -9999px;
  }
}
.login_group_container {
    margin-top: 0px !important;
}

.login_social_btn {
    margin-top: 0px !important;
}

.login_google_btn {
    width: 294px !important;
}

.login_form_btn {
    margin-top: 0px !important;
}

.login_continue_btn {
    margin-top: 0px !important;
}

.ant-btn:hover,
.ant-btn:focus {
    border-color: #47a277 !important;
}

.border {
    border: 1px solid rgb(114, 114, 114);
    margin: 42px 0px 21px;
}

.main_carousel_container {
    width: 50%;
}

.main_login_carousel {
    width: 98%;
    background-image: url(/static/media/RectangleGlassEffectCurveImage.2a833ad8.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 262px;
    text-align: center;
    border-radius: 10px;
    overflow: hidden;
    position: absolute;
    top: 60%;
}

.mid_part_container {
    width: 70%;
    margin-left: 10px;
}

.carousel_box {
    width: 82%;
}

.description_p {
    color: hsl(42, 21%, 79%);
    margin: 20px 0px;
    text-align: left;
    font-size: 20px;
}

.learn-more-txt_new {
    color: #d5cebe;
    font-size: 23px;
}

.span_login_main {
    color: #FFF;
    font-weight: 200;
    font-size: 20px;
}

.span_login_main_first {
    color: #FFF;
    font-weight: 200;
}

.hr_line {
    color: #FFF;
    margin: 15px 42px;
}

.carousel_logo_img {
    width: 173px;
    margin-top: 10px;
}

.carousel_logo_box {
    display: flex;
    padding: 25px 144px 0px 8px
}


.Lochi-Login-xyz {
    text-align: center;
    letter-spacing: 2px;
    font-size: 13px;
    padding-top: 10px;
}

.form_continue_btn_enabled {
    background-color: #47a277;
    color: #FFF !important;
}

.continue_main_btn {
    height: 53px !important;
    background-color: #9bddbf !important;
    color: #47a277 !important;
}

.Sign {
    color: #47a277;
    text-decoration: underline;
    cursor: pointer;
}

.ant-form-item {
    margin-bottom: 5px !important;
}

.login_welcome_text {
    line-height: 19px !important;
}

.login_bar_line {
    width: 289px !important;
}

.login_bar_line_box {
    margin-top: 10px !important;
}

.login_main_flow_line {
    width: 49px !important;
}

.login_main_logo {
    margin-bottom: 50px !important;
}

.ant-input:focus,
.ant-input-focused {
    border-color: #9dcfbb !important;
    box-shadow: 0 0 0 2px rgb(82 196 26 / 20%) !important;
    border-right-width: 1px;
    outline: 0;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
    border-color: #9dcfbb !important;
    box-shadow: 0 0 0 2px rgb(82 196 26 / 20%) !important;
    border-right-width: 1px;
    outline: 0;
    background: #9bddbf !important;
}

.new_login_text {
    font-weight: 700 !important;
    color: #47A277 !important;
}

.ant-modal-content {
    border-radius: 20px !important;
}

.signInModalMainDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.btnLoginModal {
    border-radius: 5px !important;
    margin: 5px !important;
    height: 45px !important;
    width: 110px !important;
    text-transform: uppercase;
    font-size: 16px !important;
}

@media(max-width:768px) {
    .imgHide {
        display: none !important;
    }
}

@media(max-width:375px) {
    .iphone_se_res {
        display: flex !important;
        width: 100% !important;
        justify-content: space-evenly !important;
    }

    .google_btn_width {
        width: 67px !important;
    }

    .iphone_se_res button {
        text-indent: -9999px;
    }
}

@media(max-width:420px) {
    .iphone_se_res {
        display: flex !important;
        width: 100% !important;
        justify-content: space-evenly !important;
    }

    .google_btn_width {
        width: 67px !important;
    }

    .iphone_se_res button {
        text-indent: -9999px;
    }
}
.main {
    margin: auto;
    padding-top: 60px;
    background: #fcfcfc !important;
}

.Jan {
    margin-top: -19px;
}

.Data-b {
    margin-top: 15px;
    width: 185px;
    border-color: #6ea08c !important;
}

.Data-a {
    border-radius: 5px;
    width: 180px;
    margin-top: 10px;
    padding-left: 13px;
}

.Data_a_border {
    border: 1px solid #47a277;
    border-radius: 5px;
    width: 180px;
    margin-top: 10px;
}

.main-b {
    padding: 11px 23px 10px
}

.text-a {
    margin-top: 10px;
}

.Data-terms {
    width: 83%;
    padding: 0px 43px;
    background: #FFF;
    height: 532px;
    overflow-y: scroll;
}

.text-b {
    margin-top: 10px;
}

.text-c {
    margin-top: 10px;
}

.text-d {
    margin-top: 10px;
}

.Data {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #6ea08c;
    font-size: large;
    margin-top: 17px;
    width: 77%;
    margin-left: 65px;
    background: #FFF;
}

.new_Heading_class {
    font-weight: 600;
    font-size: 26px;
}

.terms_condition_main {
    margin: 0 !important;
}

.Data-a_border {
    display: inherit;
    color: #47a277 !important;
    border-radius: 5px;
    width: 100%;
    margin-top: 10px;
    padding-left: 13px;
    font-weight: 700;
    cursor: pointer;
}

.Data-a_border p {
    margin-left: 10px;
}

.side_point_color {
    display: inherit;
    color: #47a277 !important;
    border: 2px solid #6ea08c !important;
    width: 100%;
    margin-top: 10px;
    padding-left: 13px;
    font-weight: 700;
    border-radius: 10px;
}

.main_hr_line {
    height: 1px;
    background-color: black;
}

.side_point_color p {
    margin-left: 10px;
}

.heading_main_term {
    display: flex;
    margin-top: 10px;
}

.reset-password-backarrow-icon {
    width: 35px;
    height: 35px;
    cursor: pointer;
}

.heading_main_term p {
    margin-left: 10px;
}

.side_point_color:hover {
    color: #47a277 !important;
}

*::-webkit-scrollbar {
    width: 7px;
}

*::-webkit-scrollbar-thumb {
    background-color: #6ea08c;
    border-radius: 10px;
    border: 1px solid #6ea08c;
}

.main_logo_box {
    display: none;
    align-items: center;
    justify-content: center;
}

.main_term_logo_bottom {
    margin: 53px 128px 25px 79px;
}


@media (max-width:450px) {
    .main_logo_box {
        display: flex !important;
        justify-content: center;
        align-items: center;
    }
}

@media (max-width:375px) {
    .Data {
        display: none !important;
    }

    .main_term_logo_bottom {
        margin: 53px 38px 25px 18px;
    }

    .Data-terms {
        width: 99%;
        padding: 0px 10px;
        background: #FFF;
        height: 532px;
        overflow-y: scroll;
    }

    .new_Heading_class {
        font-weight: 600;
        font-size: 17px;
    }

    .terms_signup_log {
        width: 90% !important;
        height: 88% !important;
    }
}

@media (max-width:412px) {
    .Data {
        display: none !important;
    }

    .new_Heading_class {
        font-weight: 600;
        font-size: 17px;
    }

    .main_term_logo_bottom {
        margin: 53px 38px 25px 18px;
    }

    .Data-terms {
        width: 99% !important;
        padding: 0px 10px !important;
        background: #FFF;
        height: 532px;
        overflow-y: scroll;
    }

    .terms_signup_log {
        width: 90% !important;
        height: 88% !important;
    }
}

.main_footer{
height: 0% !important;
}
.footer {
    height: 300px;
    display: flex;
    color: white;
    width: auto;
    justify-content: space-around;
    align-items: center;
    background-image: url(/static/media/footerCurveImage.fa9176dd.png);
    background-repeat: no-repeat;
    background-size: cover;
  }
  .footer-items {
    margin-top: 80px;
    width: 70%;
  }
  .footer-img {
    height: 150px;
    width: 180px;
  }
  .footer-logo span {
    font-size: 50px;
    padding-top: 40px;
  }
  .footer-logo p {
    font-size: 10px;
  }
  .footer-link-items {
    display: flex;
    flex-direction: column;
    margin: 20px auto;
    text-align: center;
    width: 160px;
    box-sizing: border-box;
  }
  
  .footer .ant-col-lg-6 {
    max-width: 22% !important;
  }
  .footer-link-items h6 {
    text-align: left;
  }
  .footer-list.ant-row {
    display: flex;
    justify-content: center;
  }
  .footer-link-items a {
    list-style: none;
    color: white !important;
    text-decoration: none;
    text-align: left;
  }
  .footer-collapse {
    margin: 30px 100px;
    display: none;
  }
  .footer
    .ant-space.css-dev-only-do-not-override-1me4733.ant-space-horizontal.ant-space-align-center {
    display: flex;
    justify-content: space-between;
    height: 50px;
    border-bottom: 1px solid white;
  }
  .footer-collapse {
    margin-top: 50px;
  }
  .footer .ant-collapse {
    color: white;
    font-size: 16px;
    background-color: white;
    border: 1px solid #d9d9d9;
  }
  .footer .ant-collapse > .ant-collapse-item:last-child {
    border: none;
  }
  .footer .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    color: white;
    padding: 12px 2px;
  }
  /* .ant-collapse.ant-collapse-icon-position-start.css-dev-only-do-not-override-1me4733 {
    border: none;
    background-color: transparent;
    border-radius: none;
  } */
  .footer
    .ant-collapse.ant-collapse-icon-position-end.css-dev-only-do-not-override-1me4733 {
    border: none;
    background-color: transparent;
    border-radius: none;
  }
  .footer .ant-collapse > .ant-collapse-item {
    border-bottom: 1px solid white;
    border-radius: 0px;
  }
  
  .footer-text {
    padding: 10px;
    color: rgb(54, 119, 81);
    padding: 10px;
    color: rgb(54, 119, 81);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .footer .ant-collapse > .ant-collapse-item > .ant-collapse-header{
    color: #FFF !important;
    background-color: #4fa97f !important;
  }
  .footer_heading {
    color: #FFF !important;
    font-size: 15px !important;
  }
  .footer-logo{
  padding-top: 10px; 
  }
  @media screen and (max-width: 991px) {
    .footer-items {
      margin: 10px 20px;
    }
    .footer-img {
      height: 100px;
      width: 130px;
    }
  }
  @media (max-width: 768px) {
    .footer {
      flex-direction: column-reverse;
      text-align: left;
      background-image: none;
      background: linear-gradient(
        to bottom right,
        rgb(65, 166, 127),
        rgb(108, 199, 167)
      );
      height: auto;
    }
    .footer-img {
      height: 150px;
      width: 170px;
    }
    .footer .footer-items {
      display: none;
    }
    .footer-collapse {
      display: block;
      width: 70%;
    }
  }
  @media (max-width: 480px) {
    .footer-collapse {
      margin: 30px auto;
    }
  }
  
.navbar-section {
  width: 100%;
  overflow-y: unset;
}

.navbar-logo {
  padding: 10px;
  font-size: 35px;
}

span.ant-badge.ant-badge {
  color: white;
  font-size: 24px;
  padding-left: 0px;
}

.navbar-logo:hover {
  cursor: pointer
}

.navbar-main-div {
  height: auto !important;
  width: 90%;
  margin: auto;
}

.navbar-logo-text {
  color: #389c6a;
}

.navbar-section button.navbar-toggler.collapsed {
  border: none;
  outline: none;
}

.navbar-section .navbar>.container,
.navbar-section .navbar>.container-fluid,
.navbar-section .navbar>.container-lg,
.navbar-section .navbar>.container-md,
.navbar-section .navbar>.container-sm,
.navbar-section .navbar>.container-xl,
.navbar-section .navbar>.container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.navbar-search {
  width: 60%;
  height: auto;
  padding: 5px;
  border: 1px solid grey;
  border-radius: 10px;
}

.navbar-ul {
  width: 100%;
  margin: 0 auto;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-section.me-auto.my-2.my-lg-0.navbar-nav.navbar-nav-scroll {
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.d-flex {
  border: 1px solid grey;
  width: 100%;
  margin: 10px auto;
  padding: 5px;
  border-radius: 10px;
}

.navbar-main-div .navbar-toggler {
  border: none;
}

input.me-5.form-control {
  margin-right: 1rem !important;
  border: none;
  outline: none !important;
  box-shadow: none;
  background-color: transparent;
}

.search-text {
  height: 40px;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-img {
  height: 18px;
  width: 18px;
}

.navbar-section .search-text span {
  padding-left: 15px;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
}
.navbar-section .search-text span:hover {
  color: #A5D0BA !important;
}

.search-text.ant-btn-primary {
  background-color: rgb(60, 163, 123);
  height: 41px !important;
  border-radius: 8px;
  outline: none;
  border: none;

  display: flex;
  align-items: center;
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
  background-color: #389c6a !important;
  opacity: .7;
  color: #fefefe !important;
}

.search-text.ant-btn-primary:not(:disabled):hover {
  color: #fff;
  background-color: rgb(60, 163, 123) !important;
}

.vertical-align-1 {
  margin-top: 5px;
  border-left: 1px solid grey;
  height: 28px;
  padding-right: 3px;
}

.navigation-icon-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

/* .navbar-collapse .navbar-nav-scroll {
    overflow-y: hidden !important;
    overflow-x: hidden !important;
  } */
.navigation-icon-item {
  padding-right: 10px;
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  font-size: 25px;
}

.navigation-icon-item span {
  padding-left: 20px;
}

.ant-scroll-number {
  background-color: #389c6a !important;
}

.badge_Header span {
  padding: 0 !important;
}

.vertical-align-2 {
  border-left: 1px solid black;
  margin-top: 10px;
  height: 40px;
  margin-left: 5px;
  margin-right: 5px;
}

.user-login {
  font-size: 25px;
  padding: 10px;
  display: flex;
}

.navbar-section.navbar-toggler {
  border: none;
}

.navbar-section .navbar-toggler:focus {
  box-shadow: none;
  border: none;
  padding: 0px !important;
}

.ant-select {
  display: flex !important;
  align-items: center !important;
}

img.navigation-icon {
  height: 22px;
  width: 22px;
}

/* User login dropdown css */

span.ant-dropdown-menu-title-content a {
  text-decoration: none !important;
}

.Parent-dropDown {
  position: relative;
}

.Child-dropDown {
  position: absolute;
  top: 25px;
  right: -80px;
  z-index: 1;
  background-color: white;
  border: 1px solid black;
  height: auto;
  width: 200px;
  border-radius: 20px;
}

.Child-dropDown ul {
  margin-top: 10px;
  list-style: none;
  font-size: 15px;
}

.Child-dropDown li {
  margin-bottom: 5px;
}

.dropDown-list-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 0;
}

.menu-icons {
  margin-left: 0px;
  font-size: 20px;
  color: black !important;
}

.menu-name {
  margin-left: 20px;
  font-size: 15px;
  font-weight: 400;
  color: black !important;
}

.horizontel {
  width: 100%;
  margin-left: -10px;
  color: black;
}

.zipcode-section {
  display: flex;
  padding: 5px;
  flex-direction: column;
}

.title-zipCode {
  font-weight: 600;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: unset !important;
}

.nav_main_react {
  max-height: 100px !important;
  width: 100% !important;
}

.ant-btn {
  height: 49px !important;
}

@media screen and (max-width: 991px) {
  .navbar-section {
    width: 90%;
    margin: 20px auto;
  }

  .navbar-section .search-text span {
    text-decoration: none;
    display: none;
  }

  .navbar-section .navbar-search {
    width: 100%;
  }

  .navigation-icon-item {
    padding: 10px;
    display: flex;
    width: 60%;
    justify-content: space-evenly;
    align-items: center;
    font-size: 25px;
  }

  .icon {
    width: 50%;
  }

  .vertical-align-2 {
    margin-top: 15px;
  }

  .navbar-nav-scroll {
    max-height: var(--bs-scroll-height, 2vh);
    overflow-y: none !important;
  }
}

@media (max-width: 768px) {
  .container-fluid {
    flex-direction: row-reverse;
  }

  .navbar>.container,
  .navbar>.container-fluid,
  .navbar>.container-lg,
  .navbar>.container-md,
  .navbar>.container-sm,
  .navbar>.container-xl,
  .navbar>.container-xxl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: flex-end;
  }

  .navbar-logo {
    display: none;
  }

  .navbar-logo-text {
    display: block;
    padding-left: 150px;
    color: #389c6a;
    ;
  }

  .logo-img {
    height: 50px;
    width: 50px;
    color: green;
  }
}

@media (max-width: 575px) {
  .navbar-logo-text {
    display: block;
    padding-left: 70px;
  }

  .navigation-icon-item {
    padding: 10px;
    display: flex;
    width: 40%;
    justify-content: space-evenly;
    align-items: center;
    font-size: 25px;
    margin-right: 20px;
  }

  .navbar-toggler.collapsed {
    padding: 0px !important;
  }

  .navbar-section.me-auto.my-2.my-lg-0.navbar-nav.navbar-nav-scroll {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 414px) {
  .navbar-logo-text {
    display: block;
    padding-left: 50px;
  }

  input.me-5.form-control {
    width: 175px !important;
  }

  .navigation-icon-item span {
    padding-left: 15px;
  }
}

@media (min-width: 1920px) {
  input.me-5.form-control {
    width: 100%;
  }
}


@media (max-width: 415px) {

  .target-nav {
    width: 115% !important;
    margin-left: -10px;
  }

  .d-flex {
    width: 90%;
    margin-left: -10px;
  }
}

@media (max-width:390px) {

  .d-flex {
    width: 90%;
    margin-left: -10px;
  }

  .navigation-icon-wrapper {
    margin-left: -25px;
  }

  input.me-5.form-control {
    width: 135px !important;
  }
}

.txtToggle {
  margin: 0px 5px;
  font-size: 15px;
  font-weight: 400;
}

.ant-switch-checked {
  background-color: #A5D0BA !important;
}

.ant-switch {
  background-color: #A5D0BA !important;
}

.ant-switch-handle::before {
  background-color: #389c6a !important;
}

.ant-select-dropdown {
  border-radius: 10px !important;
  border: 1px solid #161616;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: #389c6a !important;
  font-weight: 600;
  background-color: #fff !important;
}

.navbar-section .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  color: #389c6a !important;
}

.navbar-section .ant-select-arrow {
  color: #389c6a !important
}

.logo-img {
  height: 50px;
  width: 60px;
  padding-right: 10px;
}
.cart-main-container {
  padding: 10px 100px;
}

.cart-grand-total-container {
  display: flex !important;
  flex-direction: row;
  justify-content: flex-end;
  margin: auto;
}

.cart-main-heading {
  font-size: 33px;
  color: rgb(72, 162, 119);
  font-weight: 800;
  margin-left: 80px;
  margin-top: 20px;
}

.new_package_count{
  text-align: center;
}

.cart-grand-total-wrapper {
  padding: 20px 0px;
}

.main_background {
  padding: 24px 29px 23px;
}

.main_card_row {
  padding: 22px 11px;
}

.cart-horizontal-rule {
  width: 97%;
  margin: auto;
  height: 1px;
  background-color: gray;
}

.new_first_class  p{
  margin: 0 !important;
}
.main_total_price{
  font-size: 20px;
  font-weight: 500;
  color: #555555;
}

.cart-grand-total-first-row>* {
  margin: 20px 0px;
  font-size: 25px;
  font-weight: 400;
  align-items: center;
}

.subtitle{
  color: black;
  font-weight: 600;
}

.cart-price-container-bu-now-button {
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 11px;
  background-color: rgb(72, 162, 119);
  border: none;
  color: white;
  font-size: 23px;
  font-size: 400;
  border-radius: 10px;
}

.cart-grand-total-title {
  text-align: start;
  color: rgb(72, 72, 72);
}

.cart-grand-total-price {
  color: rgb(72, 162, 119);
  text-align: end;
  font-weight: 600;
}

.cart-grand-total-price_box {
  color: rgb(72, 162, 119);
  font-weight: 600;
  font-size: 30px;
  margin: 0 !important;
}

/* steps css */

.shopping-cart-steps-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 180px;
  margin:0px 20px;
}

.shopping-cart-steps-container .step1 a,
.shopping-cart-steps-container .step2 a {
  color: rgb(66, 167, 122);
  text-decoration: underline;
}

.step-arrow-page {
  margin: 6px 0 0px 5px;
  font-size: 23px;
}

/* steps css */
@media screen and (max-width: 576px) {
  .cart-main-heading {
    text-align: center;
    margin: 10px 10px;
  }
}

@media screen and (max-width: 768px) {
  .cart-main-heading {
    margin-left: 40px;
  }

  .cart-price-container-bu-now-button {
    padding: 8px;
    font-size: 17px;
  }

  .cart-grand-total-title {
    font-size: 17px;
  }

  .cart-grand-total-price {
    font-size: 17px;
  }

  .cart-grand-total-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: auto;
  }
}

/* card */
.cart-card-continer {
  margin: 20px auto;
  padding: 5px;
  width: 80%;
  height: 91%;
  background-color: #FFF;
  border-radius: 10px;
}

.cart-card-continer:focus {
  background-color: #e8e8e8 !important;
}

.shopping-cart-card-content-container {
  height: 155px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.cart-card-img {
  width: 100%;
  height: 150px;
  border-radius: 10px;
}

.cart-card-content {
  padding: 0px 25px;
}

.outOffStockContainer{
  background: #e8e8e8;
  padding: 5px;
  width: 80%;
  margin: 20px auto;
  height: 91%;
  border-radius: 10px;
  opacity: 0.5;
}


.cart-card-heading {
  font-size: 22px;
  font-weight: 700;
}

.horizontal-rule {
  width: 2px;
  height: 20px;
  background-color: black;
}

.botton-rule {
  height: 27px;
}

.cartCancelStatus {
  background-color: #fff;
  height: 114px;
  width: 213px;
  top: 6%;
  position: absolute;
  border-radius: 0px 0px 15px 15px;
  right: 26%;
}

.cartCancelTxt {
  color: #48a17e;
  top: 18px;
  position: relative;
  left: 40;
  padding-left: 47px;
}

.cartOutOfStock{
  color: #141413;
  top: 18px;
  position: relative;
  left: 40;
  width: 70%;
}

.cart-card-subtitle-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 17px;
  padding: 5px 0px;
  color: rgb(58, 58, 58);
  font-weight: 600;
}

.cart-card-subtitle-container>* {
  margin-right: 10px;
}

.cart-card-subtitle-route {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: normal;
}

.cart-card-subtitle-route>* {
  margin: 0px 5px;
}

.cart-card-description {
  width: 100%;
  color: rgb(131, 131, 131);
  font-size: 15px;
}

.button-wrapper {
  width: 100%;
  display: flex;

  align-items: center;
}

.cart-card-price-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: end;
}

.button-wrapper>* {
  margin-right: 10px;
}

.cart-card-price-container-heading {
  font-weight: 400;
  font-size: 15px;
  color: rgb(180, 180, 180);
  margin-bottom: 8px;
  text-align: end;
}

.main_your_purchase{
  overflow: scroll;
  height: 345px;

}

.main_packages_scroll{
  overflow: scroll;
  height: auto;
  scrollbar-width: none;
  max-height: 580px;
}

.main_packages_scroll::-webkit-scrollbar {
  width: 0;
}

.main_packages_scroll::-webkit-scrollbar-thumb {
  background-color: transparent
}

.new_fontsize_side{
  font-size: 23px;
  font-weight: 700;
  color:#47a277;
}



.purchaseImage_cart{
  width: 99% !important;
  height: 175px;
  border-radius: 5px;
  margin-top: 30px;
  margin-bottom: 18px;
}

.edit_icon_main{
  display: flex;
  justify-content:space-between;
}

.text_your_purchase {
  margin-top: 0.2em !important;
}

.shopping_cart_summary{
  margin-top: 0px !important;
  color: #3C3C3C;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 18px;
}

.cart-card-price-container-price {
  text-align: end;
  font-size: 30px;
  color: rgb(74, 165, 128);
  margin-bottom: 8px;
  font-weight: 700;
}

/* buttons */
.cart-card-buttons {
  font-size: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 15px 5px 0px;
  border: none;
  background: none;
  outline: none;
  text-decoration: underline;
  color: rgb(96, 148, 128);
}

.cart-card-button-icon {
  margin-right: 10px;
}

.cart-card-price-quantity-button {
  width: 100%;
  display: flex;
  padding: 4px 15px;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  border-radius: 10px;
  outline: none;
  background: none;
  border: 1px solid gray;
}

.card-price-container-res {
  display: none;
}

.card-unit-price-res,
.card-quantity-res {
  align-items: flex-start;
}

.card-description-and-buttons-res {
  display: none;
}

@media screen and (max-width: 992px) {
  .cart-card-price-container-heading {
    font-size: 13px;
  }

  .cart-card-price-container-price {
    font-size: 25px;
  }

  .cart-card-price-quantity-button {
    font-size: 17px;
  }

  .cart-card-buttons {
    font-size: 17px;
  }
}

@media screen and (max-width: 768px) {
  .cart-card-description {
    font-size: 11px;
  }

  .cart-card-continer {
    padding: 5px;
    width: 90%;
  }

  .cart-card-content {
    padding: 0px 15px;
  }

  .horizontal-rule {
    margin: 0px 10px;
  }

  .cart-card-subtitle-container>* {
    font-size: 11px;
    margin-right: 0px;
  }

  .cart-card-subtitle-container {
    justify-content: space-between;
    align-items: center;
  }
}

@media screen and (max-width: 647px) {
  .cart-card-buttons {
    font-size: 16px;
  }
}

@media screen and (max-width: 576px) {
  .cart-card-subtitle-container {
    font-size: 11px;
    display: none !important;
  }

  .cart-card-price-container-price {
    font-size: 20px;
  }

  .cart-card-heading {
    display: block;
    font-size: 20px;
  }

  .shopping-cart-card-content-container {
    height: 100px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  .card-price-container-res {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }

  .card-price-container-res>* {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .card-description-and-buttons {
    display: none;
  }

  .card-description-and-buttons-res {
    display: block;
  }

  .card-description-and-buttons-res p {
    font-size: 13px;
  }

  .cart-card-price-container {
    display: none !important;
  }

  .cart-card-subtitle-container-res {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 17px;
    padding: 5px 0px;
    color: rgb(58, 58, 58);
    margin: 10px 0px;
  }

  .cart-card-subtitle-container>* {
    margin-right: 10px;
  }

  .cart-card-subtitle-route {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cart-card-subtitle-route>* {
    margin: 0px 5px;
  }

  .horizontal-rule-res {
    width: 2px;
    height: 30px;
    background-color: black;
  }

  .cart-card-img {
    height: 100px;
  }
}

@media screen and (max-width: 495px) {
  .cart-card-heading {
    font-size: 17px;
  }
}

@media screen and (max-width: 475px) {
  .cart-card-buttons {
    font-size: 17px;
  }

  .horizontal-rule {
    height: 16px;
  }
}

@media screen and (max-width: 445px) {
  .cart-card-heading {
    font-size: 17px;
  }
}

@media screen and (max-width: 420px) {
  .cart-card-heading {
    font-size: 15px;
  }
.no-product-cart-banner-container{
    flex-wrap: wrap !important;
    margin: 0px 0px !important;
    width: 100% !important;
  }
  .no-product-cart-banner-container-img {
    width: 325px !important;
  }

}

@media screen and (max-width: 375px) {

  .no-product-cart-banner-container-img {
    width: 325px !important;
  }

  .no-product-cart-banner-container{
    flex-wrap: wrap !important;
    margin: 0px 0px !important;
    width: 100% !important;
  }

  .cart-card-heading {
    font-size: 16px;
  }

  .main_card_row {
    padding: 22px 0px
  }

  .cart-card-price-container-heading {
    font-size: 13px;
  }

  .cart-card-price-container-price {
    font-size: 19px;
  }

  .cart-card-price-quantity-button {
    font-size: 17px;
    padding: 5px 8px;
  }

  .cart-card-buttons {
    font-size: 11px;
  }

  .cart-card-price-container {
    text-align: start;
    padding: 0px 5px;
  }

  .horizontal-rule {
    height: 19px;
  }

  .botton-rule {
    height: 15px;
  }
}

.new_count_box {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
  width: 28%;
  margin-top: 59px;
}

.first {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 18%;
}

.product_menu {
  color: #555555;
  font-size: 17px;
  margin: 0 !important;
}

.hr_line_shopping {
  color: #555555;
  width: 100%;
  height: 2px;
  background: #555555;
}

.nre_class_buy_now {
  width: 100% !important;
  height: 50px !important;
  border-radius: 10px !important;
  border: 0;
  background-color: #47a277;
  color: white;
  font-size: 16px;
}

.nre_class_buy_now:hover {
  color: #47a277 !important;
  background-color: #A9D6C0 !important;
}

.sign_width{
  width: 20px;
}

.no-product-cart-banner-container {
  border-radius: 20px;
  background-color: #effff7;
  display: flex;
  position: relative;
  width: 100%;
  margin: 0px 121px;
}

.no-product-cart-banner-container-img {
  height: 180px;
  border: 3px solid var(--primary-text-color);
  border-radius: 20px;
  margin-inline: 10px;
  padding: 20px;
}

.no-results-cart-text {
  color: var(--primary-text-color);
  font-weight: bold;
  font-size: 28px;
}

.no-product-cart-component {
  padding-top: 25px;
  padding-bottom: 25px;
  padding-inline: 10px;
}

.first-product-cart-banner-text {
  font-weight: bold;
  font-size: 20px;
}

.buy_now_main {
  width: 100% !important;
}

@media screen and (max-width: 820px) {
  .new_count_box {
    width: 41%
  }
}

@media screen and (max-width: 420px) {

  .cart-main-container {
    padding: 10px 1px;
  }

  .main_background {
    padding: 24px 5px 23px !important;
  }

  .new_count_box {
    width: 100% !important;
  }

  .buy_now_main {
    width: 98% !important;
  }

  .nre_class_buy_now {
    width: 90% !important;
  }

  .cart-card-continer {
    background-color: none !important;
    width: 98% !important;
  }

  .cart-main-heading {
    margin-left: 10px;
    font-size: 22px;
    font-weight: 700;
    text-align: left;
  }

  .shopping-cart-steps-container {
    margin: 10px;
  }
}


@media (min-width:1900px) {
  .cart-main-conatiner{
    padding: 10px 308px !important;
  }
}
/* @import url('../src/font/stylesheet.css'); */





/* :root {
  --color-primary: #3f7a4f;
} */


* {
  font-family: 'Poppins', sans-serif;
}

body {
  padding-right: 0 !important;
  /* user-select: none; */
  background-coloR: #f7f7f8 !important;
}

/* iframe {
  pointer-events: none !important;          
} */

/* li {
  list-style: none;
} */

.Main-container {
  padding: 0 65px;
}

@media(max-width:767px) {
  .Main-container {
    padding: 0 30px;
  }

}

@media(max-width:575px) {
  .Main-container {
    padding: 0 15px;
  }

}

@media(max-width:375px) {
  .Main-container {
    padding: 0 10px;
  }
}

#zmmtg-root {
  display: none;
}

body {
  overflow: auto !important;
}

body>#zmmtg-root {
  display: none !important;
}

#zmmtg-root,
.meeting-client,
.meeting-client-inner {
  width: 100% !important;
  height: 100% !important;
  position: relative !important;
}

#wc-footer {
  bottom: auto !important;
  width: 100% !important;
}

#dialog-join {
  width: 100% !important;
}

#sv-active-video,
.active-main,
#sv-active-speaker-view,
.main-layout {
  height: 100% !important;
  width: 100% !important;
}

.suspension-window {
  transform: translate(-444px, 10px) !important;
}

.ant-notification-notice-close {
  color: black !important;
}

.p {
  margin-top: 0 !important;
  margin-bottom: 1em !important;
}
