.cart-main-container {
  padding: 10px 100px;
}

.cart-grand-total-container {
  display: flex !important;
  flex-direction: row;
  justify-content: flex-end;
  margin: auto;
}

.cart-main-heading {
  font-size: 33px;
  color: rgb(72, 162, 119);
  font-weight: 800;
  margin-left: 80px;
  margin-top: 20px;
}

.new_package_count{
  text-align: center;
}

.cart-grand-total-wrapper {
  padding: 20px 0px;
}

.main_background {
  padding: 24px 29px 23px;
}

.main_card_row {
  padding: 22px 11px;
}

.cart-horizontal-rule {
  width: 97%;
  margin: auto;
  height: 1px;
  background-color: gray;
}

.new_first_class  p{
  margin: 0 !important;
}
.main_total_price{
  font-size: 20px;
  font-weight: 500;
  color: #555555;
}

.cart-grand-total-first-row>* {
  margin: 20px 0px;
  font-size: 25px;
  font-weight: 400;
  align-items: center;
}

.subtitle{
  color: black;
  font-weight: 600;
}

.cart-price-container-bu-now-button {
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 11px;
  background-color: rgb(72, 162, 119);
  border: none;
  color: white;
  font-size: 23px;
  font-size: 400;
  border-radius: 10px;
}

.cart-grand-total-title {
  text-align: start;
  color: rgb(72, 72, 72);
}

.cart-grand-total-price {
  color: rgb(72, 162, 119);
  text-align: end;
  font-weight: 600;
}

.cart-grand-total-price_box {
  color: rgb(72, 162, 119);
  font-weight: 600;
  font-size: 30px;
  margin: 0 !important;
}

/* steps css */

.shopping-cart-steps-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 180px;
  margin:0px 20px;
}

.shopping-cart-steps-container .step1 a,
.shopping-cart-steps-container .step2 a {
  color: rgb(66, 167, 122);
  text-decoration: underline;
}

.step-arrow-page {
  margin: 6px 0 0px 5px;
  font-size: 23px;
}

/* steps css */
@media screen and (max-width: 576px) {
  .cart-main-heading {
    text-align: center;
    margin: 10px 10px;
  }
}

@media screen and (max-width: 768px) {
  .cart-main-heading {
    margin-left: 40px;
  }

  .cart-price-container-bu-now-button {
    padding: 8px;
    font-size: 17px;
  }

  .cart-grand-total-title {
    font-size: 17px;
  }

  .cart-grand-total-price {
    font-size: 17px;
  }

  .cart-grand-total-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: auto;
  }
}

/* card */
.cart-card-continer {
  margin: 20px auto;
  padding: 5px;
  width: 80%;
  height: 91%;
  background-color: #FFF;
  border-radius: 10px;
}

.cart-card-continer:focus {
  background-color: #e8e8e8 !important;
}

.shopping-cart-card-content-container {
  height: 155px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.cart-card-img {
  width: 100%;
  height: 150px;
  border-radius: 10px;
}

.cart-card-content {
  padding: 0px 25px;
}

.outOffStockContainer{
  background: #e8e8e8;
  padding: 5px;
  width: 80%;
  margin: 20px auto;
  height: 91%;
  border-radius: 10px;
  opacity: 0.5;
}


.cart-card-heading {
  font-size: 22px;
  font-weight: 700;
}

.horizontal-rule {
  width: 2px;
  height: 20px;
  background-color: black;
}

.botton-rule {
  height: 27px;
}

.cartCancelStatus {
  background-color: #fff;
  height: 114px;
  width: 213px;
  top: 6%;
  position: absolute;
  border-radius: 0px 0px 15px 15px;
  right: 26%;
}

.cartCancelTxt {
  color: #48a17e;
  top: 18px;
  position: relative;
  left: 40;
  padding-left: 47px;
}

.cartOutOfStock{
  color: #141413;
  top: 18px;
  position: relative;
  left: 40;
  width: 70%;
}

.cart-card-subtitle-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 17px;
  padding: 5px 0px;
  color: rgb(58, 58, 58);
  font-weight: 600;
}

.cart-card-subtitle-container>* {
  margin-right: 10px;
}

.cart-card-subtitle-route {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: normal;
}

.cart-card-subtitle-route>* {
  margin: 0px 5px;
}

.cart-card-description {
  width: 100%;
  color: rgb(131, 131, 131);
  font-size: 15px;
}

.button-wrapper {
  width: 100%;
  display: flex;

  align-items: center;
}

.cart-card-price-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: end;
}

.button-wrapper>* {
  margin-right: 10px;
}

.cart-card-price-container-heading {
  font-weight: 400;
  font-size: 15px;
  color: rgb(180, 180, 180);
  margin-bottom: 8px;
  text-align: end;
}

.main_your_purchase{
  overflow: scroll;
  height: 345px;

}

.main_packages_scroll{
  overflow: scroll;
  height: auto;
  scrollbar-width: none;
  max-height: 580px;
}

.main_packages_scroll::-webkit-scrollbar {
  width: 0;
}

.main_packages_scroll::-webkit-scrollbar-thumb {
  background-color: transparent
}

.new_fontsize_side{
  font-size: 23px;
  font-weight: 700;
  color:#47a277;
}



.purchaseImage_cart{
  width: 99% !important;
  height: 175px;
  border-radius: 5px;
  margin-top: 30px;
  margin-bottom: 18px;
}

.edit_icon_main{
  display: flex;
  justify-content:space-between;
}

.text_your_purchase {
  margin-top: 0.2em !important;
}

.shopping_cart_summary{
  margin-top: 0px !important;
  color: #3C3C3C;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 18px;
}

.cart-card-price-container-price {
  text-align: end;
  font-size: 30px;
  color: rgb(74, 165, 128);
  margin-bottom: 8px;
  font-weight: 700;
}

/* buttons */
.cart-card-buttons {
  font-size: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 15px 5px 0px;
  border: none;
  background: none;
  outline: none;
  text-decoration: underline;
  color: rgb(96, 148, 128);
}

.cart-card-button-icon {
  margin-right: 10px;
}

.cart-card-price-quantity-button {
  width: 100%;
  display: flex;
  padding: 4px 15px;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  border-radius: 10px;
  outline: none;
  background: none;
  border: 1px solid gray;
}

.card-price-container-res {
  display: none;
}

.card-unit-price-res,
.card-quantity-res {
  align-items: flex-start;
}

.card-description-and-buttons-res {
  display: none;
}

@media screen and (max-width: 992px) {
  .cart-card-price-container-heading {
    font-size: 13px;
  }

  .cart-card-price-container-price {
    font-size: 25px;
  }

  .cart-card-price-quantity-button {
    font-size: 17px;
  }

  .cart-card-buttons {
    font-size: 17px;
  }
}

@media screen and (max-width: 768px) {
  .cart-card-description {
    font-size: 11px;
  }

  .cart-card-continer {
    padding: 5px;
    width: 90%;
  }

  .cart-card-content {
    padding: 0px 15px;
  }

  .horizontal-rule {
    margin: 0px 10px;
  }

  .cart-card-subtitle-container>* {
    font-size: 11px;
    margin-right: 0px;
  }

  .cart-card-subtitle-container {
    justify-content: space-between;
    align-items: center;
  }
}

@media screen and (max-width: 647px) {
  .cart-card-buttons {
    font-size: 16px;
  }
}

@media screen and (max-width: 576px) {
  .cart-card-subtitle-container {
    font-size: 11px;
    display: none !important;
  }

  .cart-card-price-container-price {
    font-size: 20px;
  }

  .cart-card-heading {
    display: block;
    font-size: 20px;
  }

  .shopping-cart-card-content-container {
    height: 100px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  .card-price-container-res {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }

  .card-price-container-res>* {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .card-description-and-buttons {
    display: none;
  }

  .card-description-and-buttons-res {
    display: block;
  }

  .card-description-and-buttons-res p {
    font-size: 13px;
  }

  .cart-card-price-container {
    display: none !important;
  }

  .cart-card-subtitle-container-res {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 17px;
    padding: 5px 0px;
    color: rgb(58, 58, 58);
    margin: 10px 0px;
  }

  .cart-card-subtitle-container>* {
    margin-right: 10px;
  }

  .cart-card-subtitle-route {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cart-card-subtitle-route>* {
    margin: 0px 5px;
  }

  .horizontal-rule-res {
    width: 2px;
    height: 30px;
    background-color: black;
  }

  .cart-card-img {
    height: 100px;
  }
}

@media screen and (max-width: 495px) {
  .cart-card-heading {
    font-size: 17px;
  }
}

@media screen and (max-width: 475px) {
  .cart-card-buttons {
    font-size: 17px;
  }

  .horizontal-rule {
    height: 16px;
  }
}

@media screen and (max-width: 445px) {
  .cart-card-heading {
    font-size: 17px;
  }
}

@media screen and (max-width: 420px) {
  .cart-card-heading {
    font-size: 15px;
  }
.no-product-cart-banner-container{
    flex-wrap: wrap !important;
    margin: 0px 0px !important;
    width: 100% !important;
  }
  .no-product-cart-banner-container-img {
    width: 325px !important;
  }

}

@media screen and (max-width: 375px) {

  .no-product-cart-banner-container-img {
    width: 325px !important;
  }

  .no-product-cart-banner-container{
    flex-wrap: wrap !important;
    margin: 0px 0px !important;
    width: 100% !important;
  }

  .cart-card-heading {
    font-size: 16px;
  }

  .main_card_row {
    padding: 22px 0px
  }

  .cart-card-price-container-heading {
    font-size: 13px;
  }

  .cart-card-price-container-price {
    font-size: 19px;
  }

  .cart-card-price-quantity-button {
    font-size: 17px;
    padding: 5px 8px;
  }

  .cart-card-buttons {
    font-size: 11px;
  }

  .cart-card-price-container {
    text-align: start;
    padding: 0px 5px;
  }

  .horizontal-rule {
    height: 19px;
  }

  .botton-rule {
    height: 15px;
  }
}

.new_count_box {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
  width: 28%;
  margin-top: 59px;
}

.first {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 18%;
}

.product_menu {
  color: #555555;
  font-size: 17px;
  margin: 0 !important;
}

.hr_line_shopping {
  color: #555555;
  width: 100%;
  height: 2px;
  background: #555555;
}

.nre_class_buy_now {
  width: 100% !important;
  height: 50px !important;
  border-radius: 10px !important;
  border: 0;
  background-color: #47a277;
  color: white;
  font-size: 16px;
}

.nre_class_buy_now:hover {
  color: #47a277 !important;
  background-color: #A9D6C0 !important;
}

.sign_width{
  width: 20px;
}

.no-product-cart-banner-container {
  border-radius: 20px;
  background-color: #effff7;
  display: flex;
  position: relative;
  width: 100%;
  margin: 0px 121px;
}

.no-product-cart-banner-container-img {
  height: 180px;
  border: 3px solid var(--primary-text-color);
  border-radius: 20px;
  margin-inline: 10px;
  padding: 20px;
}

.no-results-cart-text {
  color: var(--primary-text-color);
  font-weight: bold;
  font-size: 28px;
}

.no-product-cart-component {
  padding-top: 25px;
  padding-bottom: 25px;
  padding-inline: 10px;
}

.first-product-cart-banner-text {
  font-weight: bold;
  font-size: 20px;
}

.buy_now_main {
  width: 100% !important;
}

@media screen and (max-width: 820px) {
  .new_count_box {
    width: 41%
  }
}

@media screen and (max-width: 420px) {

  .cart-main-container {
    padding: 10px 1px;
  }

  .main_background {
    padding: 24px 5px 23px !important;
  }

  .new_count_box {
    width: 100% !important;
  }

  .buy_now_main {
    width: 98% !important;
  }

  .nre_class_buy_now {
    width: 90% !important;
  }

  .cart-card-continer {
    background-color: none !important;
    width: 98% !important;
  }

  .cart-main-heading {
    margin-left: 10px;
    font-size: 22px;
    font-weight: 700;
    text-align: left;
  }

  .shopping-cart-steps-container {
    margin: 10px;
  }
}


@media (min-width:1900px) {
  .cart-main-conatiner{
    padding: 10px 308px !important;
  }
}